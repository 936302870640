import * as React from "react"
import { Link } from "gatsby"

const PostCard = ({ key, title, date, description, slug }) => (
  <article
    className="max-w-4xl mt-6 mx-3 px-10 py-6 bg-white rounded-lg shadow-md"
    key={key}
  >
    <header className="flex justify-between items-center">
      <span className="font-light text-gray-600">{date}</span>
      {/* Tag */}
      {/* <a
          href="#"
          className="px-2 py-1 bg-gray-600 text-gray-100 font-bold rounded hover:bg-gray-500"
        >
          Laravel
        </a> */}
    </header>
    <section className="mt-2">
      <Link
        to={slug}
        className="text-2xl text-gray-700 font-bold hover:underline"
      >
        {title}
      </Link>
      <p
        className="mt-2 text-gray-600"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
        itemProp="description"
      />
    </section>
    <footer className="flex justify-between items-center mt-4">
      <Link to={slug} className="text-blue-500 hover:underline">
        記事を読む
      </Link>
      {/* <div>
          <a href="#" className="flex items-center">
            <img
              src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=731&amp;q=80"
              alt="avatar"
              className="mx-4 w-10 h-10 object-cover rounded-full hidden sm:block"
            />
            <h1 className="text-gray-700 font-bold hover:underline">
              Alex John
            </h1>
          </a>
        </div> */}
    </footer>
  </article>
)

export default PostCard
