import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostCard from "../components/post-card"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes.filter(node =>
    node.fields.slug.startsWith("/entry/")
  )

  const entryLists = posts => {
    if (posts.length === 0) {
      return <p>エントリーが見つかりません。</p>
    }

    return posts
      .map(post => {
        const title = post.frontmatter.title || post.fields.slug
        const date = post.frontmatter.date
        const description = post.frontmatter.description || post.excerpt
        const slug = post.fields.slug
        return (
          <PostCard
            key={slug}
            title={title}
            date={date}
            description={description}
            slug={slug}
          />
        )
      })
      .reduce((prev, curr) => [prev, "", curr])
  }

  /* https://tailwindcomponents.com/component/blog-page */

  return (
    <Layout location={location} title={siteTitle}>
      <Seo />
      {/* <div className="flex items-center justify-between">
            <h1 className="text-xl font-bold text-gray-700 md:text-2xl">
              記事一覧
            </h1>
          </div> */}
      {entryLists(posts)}
      {/* Pager */}
      {/* <div className="mt-8">
            <div className="flex">
              <a
                href="#"
                className="mx-1 px-3 py-2 bg-white text-gray-500 font-medium rounded-md cursor-not-allowed"
              >
                previous
              </a>
              <a
                href="#"
                className="mx-1 px-3 py-2 bg-white text-gray-700 font-medium hover:bg-blue-500 hover:text-white rounded-md"
              >
                1
              </a>
              <a
                href="#"
                className="mx-1 px-3 py-2 bg-white text-gray-700 font-medium hover:bg-blue-500 hover:text-white rounded-md"
              >
                2
              </a>
              <a
                href="#"
                className="mx-1 px-3 py-2 bg-white text-gray-700 font-medium hover:bg-blue-500 hover:text-white rounded-md"
              >
                3
              </a>
              <a
                href="#"
                className="mx-1 px-3 py-2 bg-white text-gray-700 font-medium hover:bg-blue-500 hover:text-white rounded-md"
              >
                Next
              </a>
            </div>
          </div> */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY/MM/DD")
          title
          description
        }
      }
    }
  }
`
